import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Lead = App.Models.Lead
import Document = App.Models.Document
import LeadFinancialNote = App.Models.LeadFinancialNote

export interface LeadState {
  is_assigned: boolean
  is_cold: boolean
  lead_id: string | null
  lead: Lead | null
  assigned_counsellor_id: string | null
  cold_reason: string
  cold_status: string
  reload_all_leads: boolean
  current_leads_filters: {
    branch_id?: string
    counsellor_id?: string
    lead_form_id?: string
    lead_source?: string
    sub_lead_source?: string
    preferred_country?: string
    created_at?: string
    city?: string
    state?: string
    from_date?: string
    to_date?: string
    preferred_countries?: string[]
    last_call_status?: string
    last_call_outcome?: string
  }
  previous_leads_filters: {
    branch_id?: string
    counsellor_id?: string
    lead_form_id?: string
    lead_source?: string
    sub_lead_source?: string
    preferred_country?: string
    created_at?: string
    city?: string
    state?: string
    from_date?: string
    to_date?: string
    preferred_countries?: string[]
    last_call_status?: string
    last_call_outcome?: string
  }
  show_all_leads_filter_modal: boolean
  show_lead_import_modal: boolean
  show_upload_document_modal: boolean
  show_attach_file_modal: boolean
  show_add_quick_lead_modal: boolean
  show_edit_personal_detail_modal: boolean
  show_edit_preference_detail_modal: boolean
  show_edit_education_detail_modal: boolean
  show_edit_language_aptitude_test_detail_modal: boolean
  show_edit_work_experience_detail_modal: boolean
  show_edit_visa_passport_detail_modal: boolean
  show_edit_family_detail_modal: boolean
  branch_id: string | null
  visitor_lead_id: string | null
  ielts_lead_id: string | null
  counsellor_id: string | null
  is_counsellor_changed: boolean
  is_branch_changed: boolean
  counselling_counsellor_id: string | null
  is_counselling_counsellor_changed: boolean
  admission_manager_id: string | null
  is_admission_manager_changed: boolean
  visa_manager_id: string | null
  is_visa_manager_changed: boolean
  portal: string
  document_belongs_to: string
  document_entity: string
  document_entity_id: string | null
  download_documents: Document[] | null
  show_document_download_modal: boolean
  selected_lead_ids: string[]
  selected_leads: Lead[]
  quick_action_show_lead_counsellor_modal: boolean
  quick_action_show_status_modal: boolean
  lead_type: string | null
  show_ielts_visitor_lead_convert_modal: boolean
  selected_lead_id: number | null
  is_leads_notes_opened: boolean
  task_counsellor_id: string | null
  follow_up_counsellor_id: string | null
  show_note_modal: boolean
  note: LeadFinancialNote | null
  selected_lead_financial_status: string | null
  branchIds: string[]
  leadManagerIds: string[]
  leadAssignForm: {
    assign_type: string
    counsellor_id: string
    counsellor_id_one: string
    counsellor_id_two: string
    counsellor_id_three: string
    admission_manager_id: string
    admission_manager_id_five: string
    admission_manager_id_four: string
    admission_manager_id_one: string
    admission_manager_id_three: string
    admission_manager_id_two: string
  }
  document: Document | null
  show_edit_upload_document_modal: boolean
  show_lead_details_customize_drawer: boolean
  show_information_form: boolean
  lead_status: string | null
  selected_lead: Lead | null
  show_lead_download_profile_drawer: boolean
}

const initialState: LeadState = {
  is_assigned: false,
  is_cold: false,
  lead_id: null,
  lead: null,
  assigned_counsellor_id: null,
  cold_reason: '',
  cold_status: '',
  current_leads_filters: {
    branch_id: '',
    counsellor_id: '',
    lead_form_id: '',
    lead_source: '',
    sub_lead_source: '',
    preferred_country: '',
    created_at: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
    last_call_status: '',
    last_call_outcome: '',
  },
  previous_leads_filters: {
    branch_id: '',
    counsellor_id: '',
    lead_form_id: '',
    lead_source: '',
    sub_lead_source: '',
    preferred_country: '',
    created_at: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
    last_call_status: '',
    last_call_outcome: '',
  },
  reload_all_leads: false,
  show_all_leads_filter_modal: false,
  show_lead_import_modal: false,
  show_upload_document_modal: false,
  show_attach_file_modal: false,
  show_add_quick_lead_modal: false,
  show_edit_personal_detail_modal: false,
  show_edit_preference_detail_modal: false,
  show_edit_education_detail_modal: false,
  show_edit_language_aptitude_test_detail_modal: false,
  show_edit_work_experience_detail_modal: false,
  show_edit_visa_passport_detail_modal: false,
  show_edit_family_detail_modal: false,
  branch_id: null,
  visitor_lead_id: null,
  ielts_lead_id: null,
  counsellor_id: null,
  is_counsellor_changed: false,
  is_branch_changed: false,
  counselling_counsellor_id: null,
  is_counselling_counsellor_changed: false,
  admission_manager_id: null,
  is_admission_manager_changed: false,
  visa_manager_id: null,
  is_visa_manager_changed: false,
  portal: 'Agency',
  document_belongs_to: 'Applicant',
  document_entity: 'Lead',
  document_entity_id: null,
  download_documents: null,
  show_document_download_modal: false,
  selected_lead_ids: [],
  selected_leads: [],
  quick_action_show_lead_counsellor_modal: false,
  quick_action_show_status_modal: false,
  lead_type: null,
  show_ielts_visitor_lead_convert_modal: false,
  selected_lead_id: null,
  is_leads_notes_opened: false,
  task_counsellor_id: null,
  follow_up_counsellor_id: null,
  show_note_modal: false,
  note: null,
  selected_lead_financial_status: null,
  branchIds: [],
  leadManagerIds: [],
  leadAssignForm: {
    assign_type: 'assign',
    counsellor_id: '',
    counsellor_id_one: '',
    counsellor_id_two: '',
    counsellor_id_three: '',
    admission_manager_id: '',
    admission_manager_id_five: '',
    admission_manager_id_four: '',
    admission_manager_id_one: '',
    admission_manager_id_three: '',
    admission_manager_id_two: '',
  },
  document: null,
  show_edit_upload_document_modal: false,
  show_lead_details_customize_drawer: false,
  show_information_form: false,
  lead_status: null,
  selected_lead: null,
  show_lead_download_profile_drawer: false,
}

export const leadSlice = createSlice({
  name: 'leadSlice',
  initialState,
  reducers: {
    updateIsAssigned(state, action: PayloadAction<boolean>) {
      state.is_assigned = action.payload
    },
    updateIsCold(state, action: PayloadAction<boolean>) {
      state.is_cold = action.payload
    },
    updateColdLeadStatus(state, action: PayloadAction<string>) {
      state.cold_status = action.payload
    },
    updateColdLeadReason(state, action: PayloadAction<string>) {
      state.cold_reason = action.payload
    },
    updateLeadId(state, action: PayloadAction<string | null>) {
      state.lead_id = action.payload
    },
    updateLead(state, action: PayloadAction<Lead | null>) {
      state.lead = action.payload
    },
    updateAssignedCounsellorId(state, action: PayloadAction<string | null>) {
      state.assigned_counsellor_id = action.payload
    },
    updateShowLeadImportModal(state, action: PayloadAction<boolean>) {
      state.show_lead_import_modal = action.payload
    },
    updateShowNoteModal(
      state,
      action: PayloadAction<{
        show_note_modal: boolean
        note: LeadFinancialNote | null
      }>
    ) {
      state.show_note_modal = action.payload.show_note_modal
      state.note = action.payload.note
    },
    updatedSelectedLeadFinancialStatus(state, action: PayloadAction<string | null>) {
      state.selected_lead_financial_status = action.payload
    },
    updateDocumentBelongsTo(state, action: PayloadAction<string>) {
      state.document_belongs_to = action.payload
    },
    updateShowUploadDocumentModal(
      state,
      action: PayloadAction<{
        show: boolean
        document_entity: string
        document_entity_id: string | null
        lead_id: string | null
        belongs_to: string
      }>
    ) {
      state.show_upload_document_modal = action.payload.show
      state.document_entity = action.payload.document_entity
      state.document_entity_id = action.payload.document_entity_id
      state.lead_id = action.payload.lead_id
      state.document_belongs_to = action.payload.belongs_to
    },
    updateDownloadDocumentsModal(
      state,
      action: PayloadAction<{
        show: boolean
        lead_id: string | null
        documents: Document[] | null
      }>
    ) {
      state.show_document_download_modal = action.payload.show
      state.lead_id = action.payload.lead_id
      state.download_documents = action.payload.documents
    },
    updateShowAddQuickLeadModal(state, action: PayloadAction<boolean>) {
      state.show_add_quick_lead_modal = action.payload
    },
    updateShowEditPersonalDetailModal(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_edit_personal_detail_modal = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowEditPreferenceDetailModal(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_edit_preference_detail_modal = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowEditEducationDetailModal(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_edit_education_detail_modal = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowEditLanguageAptitudeTestDetailModal(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_edit_language_aptitude_test_detail_modal = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowEditWorkExperienceDetailModal(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_edit_work_experience_detail_modal = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowEditVisaPassportDetailModal(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_edit_visa_passport_detail_modal = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowEditFamilyDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        lead: Lead | null
      }>
    ) {
      state.show_edit_family_detail_modal = action.payload.show
      state.lead = action.payload.lead
    },
    updateIsLeadCounsellorChanged(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        counsellor_id: string | null
        lead_id: string | null
      }>
    ) {
      state.is_counsellor_changed = action.payload.show
      state.branch_id = action.payload.branch_id
      state.counsellor_id = action.payload.counsellor_id
      state.lead_id = action.payload.lead_id
    },
    updateIsLeadBranchChanged(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        lead_id: string | null
        counsellor_id: string | null
        counselling_counsellor_id: string | null
        admission_manager_id: string | null
        visa_manager_id: string | null
        task_counsellor_id: string | null
        follow_up_counsellor_id: string | null
      }>
    ) {
      state.is_branch_changed = action.payload.show
      state.branch_id = action.payload.branch_id
      state.lead_id = action.payload.lead_id
      state.counsellor_id = action.payload.counsellor_id
      state.counselling_counsellor_id = action.payload.counselling_counsellor_id
      state.admission_manager_id = action.payload.admission_manager_id
      state.visa_manager_id = action.payload.visa_manager_id
      state.task_counsellor_id = action.payload.task_counsellor_id
      state.follow_up_counsellor_id = action.payload.follow_up_counsellor_id
    },
    showAllLeadFilterModal(state) {
      state.show_all_leads_filter_modal = true
    },
    hideAllLeadFilterModal(state) {
      state.show_all_leads_filter_modal = false
    },
    reloadAllLeads(state, action: PayloadAction<boolean>) {
      state.reload_all_leads = action.payload
    },
    updateCurrentLeadFilters(
      state,
      action: PayloadAction<{
        branch_id?: string
        counsellor_id?: string
        lead_form_id?: string
        lead_source?: string
        sub_lead_source?: string
        preferred_country?: string
        created_at?: string
        city?: string
        state?: string
        from_date?: string
        to_date?: string
        preferred_countries?: string[]
        last_call_status?: string
        last_call_outcome?: string
      }>
    ) {
      state.current_leads_filters = action.payload
    },
    updatePreviousLeadFilters(
      state,
      action: PayloadAction<{
        branch_id?: string
        counsellor_id?: string
        lead_form_id?: string
        lead_source?: string
        sub_lead_source?: string
        preferred_country?: string
        created_at?: string
        city?: string
        state?: string
        from_date?: string
        to_date?: string
        preferred_countries?: string[]
        last_call_status?: string
        last_call_outcome?: string
      }>
    ) {
      state.previous_leads_filters = action.payload
    },
    updateLeadBranchId(state, action: PayloadAction<string | null>) {
      state.branch_id = action.payload
    },
    updateSelectedLeadIds(state, action: PayloadAction<string[]>) {
      state.selected_lead_ids = action.payload
    },
    updateSelectedLeads(state, action: PayloadAction<Lead[]>) {
      state.selected_leads = action.payload
    },
    updateQuickActionShowLeadCounsellorModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
      }>
    ) {
      state.quick_action_show_lead_counsellor_modal = action.payload.show
      state.branch_id = action.payload.branch_id
    },
    updateShowIELTSVisitorLeadConvertModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        lead_id: string | null
        lead_type: string | null
      }>
    ) {
      state.show_ielts_visitor_lead_convert_modal = action.payload.show
      state.branch_id = action.payload.branch_id
      state.lead_id = action.payload.lead_id
      state.lead_type = action.payload.lead_type
    },
    updateIsLeadsNotesOpened(
      state,
      action: PayloadAction<{ selected_lead_id: number | null; is_leads_notes_opened: boolean }>
    ) {
      state.selected_lead_id = action.payload.selected_lead_id
      state.is_leads_notes_opened = action.payload.is_leads_notes_opened
    },
    updateShowAttachFileModal(state, action: PayloadAction<boolean>) {
      state.show_attach_file_modal = action.payload
    },
    updateBranchIds(state, action: PayloadAction<string[]>) {
      state.branchIds = action.payload
    },
    updateLeadManagerIds(state, action: PayloadAction<string[]>) {
      state.leadManagerIds = action.payload
    },
    updateLeadAssignForm(
      state,
      action: PayloadAction<{
        assign_type: string
        counsellor_id: string
        counsellor_id_one: string
        counsellor_id_two: string
        counsellor_id_three: string
        admission_manager_id: string
        admission_manager_id_five: string
        admission_manager_id_four: string
        admission_manager_id_one: string
        admission_manager_id_three: string
        admission_manager_id_two: string
      }>
    ) {
      state.leadAssignForm = action.payload
    },
    updateShowEditUploadDocumentModal(
      state,
      action: PayloadAction<{
        show: boolean
        document_entity: string
        document_entity_id: string | null
        lead_id: string | null
        belongs_to: string
        document: Document | null
      }>
    ) {
      state.show_edit_upload_document_modal = action.payload.show
      state.document_entity = action.payload.document_entity
      state.document_entity_id = action.payload.document_entity_id
      state.lead_id = action.payload.lead_id
      state.document_belongs_to = action.payload.belongs_to
      state.document = action.payload.document
    },
    updateShowLeadDetailsCustomizeDrawer(state, action: PayloadAction<boolean>) {
      state.show_lead_details_customize_drawer = action.payload
    },
    updateShowInformationFormDrawer(state, action: PayloadAction<boolean>) {
      state.show_information_form = action.payload
    },
    updateLeadStatus(state, action: PayloadAction<string | null>) {
      state.lead_status = action.payload
    },
    updateSelectedLead(state, action: PayloadAction<Lead | null>) {
      state.selected_lead = action.payload
    },
    updateShowLeadDownloadProfileDrawer(
      state,
      action: PayloadAction<{
        selected_lead: Lead | null
        show_lead_download_profile_drawer: boolean
      }>
    ) {
      state.selected_lead = action.payload.selected_lead
      state.show_lead_download_profile_drawer = action.payload.show_lead_download_profile_drawer
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateIsAssigned,
  updateIsCold,
  updateColdLeadStatus,
  updateColdLeadReason,
  updateLeadId,
  updateLead,
  updateShowAddQuickLeadModal,
  updateAssignedCounsellorId,
  updateShowLeadImportModal,
  updateDocumentBelongsTo,
  updateShowUploadDocumentModal,
  updateShowEditPersonalDetailModal,
  updateShowEditPreferenceDetailModal,
  updateShowEditEducationDetailModal,
  updateShowEditLanguageAptitudeTestDetailModal,
  updateShowEditWorkExperienceDetailModal,
  updateShowEditVisaPassportDetailModal,
  updateShowEditFamilyDetailModal,
  updateIsLeadCounsellorChanged,
  updateIsLeadBranchChanged,
  showAllLeadFilterModal,
  hideAllLeadFilterModal,
  updateCurrentLeadFilters,
  updatePreviousLeadFilters,
  reloadAllLeads,
  updateLeadBranchId,
  updateDownloadDocumentsModal,
  updateSelectedLeadIds,
  updateSelectedLeads,
  updateQuickActionShowLeadCounsellorModal,
  updateShowIELTSVisitorLeadConvertModal,
  updateIsLeadsNotesOpened,
  updateShowAttachFileModal,
  updateShowNoteModal,
  updatedSelectedLeadFinancialStatus,
  updateBranchIds,
  updateLeadManagerIds,
  updateLeadAssignForm,
  updateShowEditUploadDocumentModal,
  updateShowLeadDetailsCustomizeDrawer,
  updateShowInformationFormDrawer,
  updateLeadStatus,
  updateSelectedLead,
  updateShowLeadDownloadProfileDrawer,
} = leadSlice.actions

export default leadSlice.reducer
